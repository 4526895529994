//
// ACCORDION
//

.accordion {
    // Card
    .card {
        .card-header {
            cursor: pointer;
            padding: 0;
            background-color: transparent;

            .card-title {
                padding: .75rem 2.15rem 0.75rem 1.25rem;
                margin-bottom: 0;
                font-weight: $font-weight-medium;
                display: flex;
                align-items: center;

                i {
                    margin-right: $spacer;
                }

                @include media-breakpoint-up(sm){
                    padding: $card-spacer-y * 1.5 $card-spacer-x * 2;
                }
            }
        }

        p{
            line-height: 1.7;
        }
    }

    // List group item
    .list-group-item {

        border-width: 0 0 1px 0;

        &:last-of-type {
            border: 0;
            border-bottom: 1px;
        }
    }
    
    // Accordion ungrouped
    &.accordion-ungrouped {
        .card {
            margin-bottom: $spacer;
            border-radius: $border-radius;
            border: 1px solid $border-color;

            &:last-child {
                margin-bottom: 0;
            }

            &:not(:first-of-type):not(:last-of-type) {
                border: 1px solid $border-color;
            }
        }
    }

    // Accordion minimal
    &.accordion-minimal {

        .card,
        .card-header {
            border: 0 !important;
        }
    }

    // Accordion arrow toggler
    &.accordion-arrow-toggler {

        .card-title {

            &::after {
                content: "";
                display: inline-block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E %3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' /%3E %3C/svg%3E");                position: absolute;
                right: $spacer;
                font-size: 1.125rem;
                height: $spacer;
                width: $spacer;
                transition: all 0.3s ease-in;
                transform: rotate(180deg);
            }

            &.collapsed {
                &::after {
                    content: "";
                    transform: rotate(0deg);
                }
            }
        }
    }
}

