//
// LIST 
// 

// List style
.list{

    margin: 0;
    padding: 0;
    list-style: none;
    
    li:not(:last-child){
        margin-bottom: $spacer;
    }


    &.list-spacing-sm{
        
        li:not(:last-child){
            margin-bottom: $spacer * .5;
        }
    }
    &.list-spacing-md{
        
        li:not(:last-child){
            margin-bottom: $spacer * 1.5;
        }
    }

    &.list-spacing-lg{
        
        li:not(:last-child){
            margin-bottom: $spacer * 2;
        }
    }
    &.list-spacing-xl{
        
        li:not(:last-child){
            margin-bottom: $spacer * 3;
        }
    }
}

// Styled List
.list-styled {

    ul,
    ol {
        padding-left: 1.5625rem;
        line-height: 1.625rem;
        list-style-type: none;

        li {
            position: relative;
            margin-top: 0.5rem;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }

            &>ul,
            ol {
                margin-top: 0;
            }
        }
    }

    ul {
        &>li {
            &::before {
                width: 0.5rem;
                height: 0.5rem;
                background-color: $gray-400;
                border-radius: 50%;
                transform: translate(-1.4375rem, 0.625rem);
            }
        }
    }

    ol {
        counter-reset: list-items;

        &>li {
            counter-increment: list-items;

            &::before {
                content: counter(list-items) ". ";
                color: $primary;
                font-weight: $font-weight-semibold;
                transform: translate(-1.4375rem, 0);
            }
        }
    }

}
