//
// MASONRY GRID 
// 
.masonry-grid[data-columns] {
    margin: {
      right: -($grid-gutter-width / 2);
      left: -($grid-gutter-width / 2);
    }
    &:not(.overflow-hidden) {
      overflow: visible !important;
    }
  
    @include clearfix;
  
    .masonry-grid-item {
      width: 100%;
      float: left;
      margin-bottom: 1.5rem;
      padding: {
        right: $grid-gutter-width / 2;
        left: $grid-gutter-width / 2;
      }
    }
  }
  
  .masonry-grid[data-columns='1'] .masonry-grid-item {
    width: 100%;
  }
  
  @include media-breakpoint-up(sm) {
    .masonry-grid[data-columns='2'],
    .masonry-grid[data-columns='3'],
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: percentage(1); }
    }
  }
  @include media-breakpoint-up(md) {
    .masonry-grid[data-columns='3'],
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: percentage(1 / 2); }
    }
  }
  @include media-breakpoint-up(lg) {
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: percentage(1 / 4); }
    }
  }
  @include media-breakpoint-up(xl) {
    .masonry-grid[data-columns='3'] {
      .masonry-grid-item { width: percentage(1 / 3);}
    }
    .masonry-grid[data-columns='5'] {
      .masonry-grid-item { width: percentage(1 / 6);}
    }
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: percentage(1 / 6); }
    }
  }
  