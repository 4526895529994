//
// BASE STYLES
// 

body{
    overflow-x: hidden; //Prevent horizontal scroll for narrow devices
}


html, body { overscroll-behavior: none; }

// 
// Typography
//
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    letter-spacing: -0.03em;
}


section{
    position: relative;
}

// Link
.reset-link{
    color: inherit;
    text-decoration: none;
    display: inline-block;

    &:hover{
        text-decoration: none;
    }
}

a{
    &:hover{
        text-decoration: none;
    }
}

// List
.reset-list{
    list-style: none;
    margin: 0;
    padding: 0;
}


b,
strong {
  font-weight: $font-weight-semibold; // Add the correct font weight in Chrome, Edge, and Safari
}

blockquote{
    border: 1px solid $border-color;
    padding: 2rem 2rem;
    // margin: 0 5rem;
    font-size: 1.2rem;
    letter-spacing: -0.03em;
    line-height: 1.6;
    font-weight: $font-weight-base;
    color: $gray-700;
}

article{

    p{
        margin-bottom: 2rem;
        line-height: 1.7;
    }

    mark{
        background: $primary;
        color: $white;
    }

    h5{
        margin-top: 3rem !important;
    }

    ul{
        li{
            margin-bottom: 1rem;
        }
    }


}

.blockquote{
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.4;
}