//
// CARD
// 

.card{
    overflow: hidden;

    &.card-accent-top:before {
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        height: 0.5rem;
        width: 100%;
        border-radius: $card-border-radius $card-border-radius 0 0;
    }
}


// Testimonial card style
.testimonial-card{
    box-shadow: rgba(0,12,32,.04) 0 0 1px 0, rgba(10,31,68,.06) 0 0.625rem 1rem 0;
    background-color: $gray-700;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.3125rem;
    height: 15.625rem;
    color: $white;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    transition: 500ms;

    @include media-breakpoint-up(md){
        height: 21.875rem;
        flex: 0 1 calc(50% - 1rem);
    }

    @include media-breakpoint-up(lg){
        flex: 0 1 calc(20% - 1rem);
    }

    .testimonial-body{
        padding: 0 1.2em .6em;
    }

    &.testimonial-card-bg-1{
        background-image: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.9)),url(./../images/people/customers/customers-12.jpg);
    }

    &.testimonial-card-bg-2{
        background-image: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.9)),url(./../images/people/customers/customers-13.jpg);
    }
}

// Customer card style
.customers-card{
    border: 0;
    box-shadow: $box-shadow-smooth-1;
    transition: box-shadow 0.2s ease-out;


    &:hover{
        box-shadow: 0 0.625rem 0.9375rem -3px rgba(0,0,0,.1), 0 0.25rem 0.375rem -2px rgba(0,0,0,.05);
        transition: box-shadow 0.2s ease-out;
    }

    .card-img-block{
        height: 9.375rem;
        max-height: 9.375rem;
        overflow: hidden;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}


// Blog card style
.blog-card {

    a{
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        display: block;

        &:focus,
        &:hover,
        &:active{
            transition: all 0.2s ease-in;
            color: $primary;
            text-decoration: none;
        }
    }

    &:hover{
        background-color: $gray-50;
        .blog-cover img{
            transform: rotate(5deg) scale(1.2);
        }
        h5{
            color: $primary;
        }
    }

}

// Event card style
.card-event{
    position: relative;

    .badge-event{
        position: absolute;
        top: 0.625rem;
        left:0.625rem;
    }
}

// Team Card
.team-card{
    overflow: hidden;
    position: relative;

    &:hover{
        .team-card-img img{
            transform: scale(1.1);
            filter: grayscale(0);
        }

        .team-card-img .social-icons-group i{
            transform: translateX(0);
            box-shadow: $box-shadow;
        }
    }


    &.rounded-0{
        .team-card-img{
            border-radius: 0;

            img{
                border-radius: 0;
            }
        }
    }

    .team-card-img{
        border-radius: 0.5rem;
        position: relative;
        overflow: hidden;

        img{
            filter: grayscale(1);
            transition: all 0.4s ease-in-out;
        }

        .social-icons-group{
            position: absolute;
            top: 0.25rem;
            right: 1rem;

            i{
                display: block;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                margin-top: 0.375rem;
                border-radius: 50%;
                color: $white;
                transform: translateX(90px);
                transition: all 0.4s ease-in-out;
            }
        }
    }

    .team-card-body{
        text-align: center;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.5rem;
    }
}