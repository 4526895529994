//
// NAV
// 


// Nav style
.nav{

    &.nav-light{
        .nav-link{
            color: rgba($color: $white, $alpha: 0.7);

            &:not(.active):hover{
                color: $white;
            }

            &.active{
                color: $primary;
            }
        }
    }

    &.nav-x-0 {
        .nav-link {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.nav-line-animated{
        .nav-item{
            .nav-link{
                position: relative;
                color: inherit;
                font-weight: $font-weight-medium;
                padding-bottom: $spacer / 1.2;
    
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    bottom: 0px;
                    left: 0px;
                    display: block;
                    background: rgba(0, 0, 0, 0.08);
                }
    
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    bottom: 0px;
                    left: 0px;
                    display: block;
                    background: $primary;
                    transform: scaleX(0);
                    transform-origin: right center 0px;
                    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    
                }
    
    
                &.active{
                    color: $primary;

                    &::before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 0.1875rem;
                        bottom: 0px;
                        left: 0px;
                        display: block;
                    }
    
                    &::after{
                        transform: scaleX(1);
                        transform-origin: left center 0px;
                        transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68) 0s;
                    }
                }
            }
        }
    }
}

// Boxed nav style
.nav-boxed {
    .nav-item {

        .nav-link {
            border-radius: $border-radius;
            transition: all .2s ease-in;
            padding: 0.5rem 1.5rem;

            i{
                color: $gray-400;
            }
            p{
                display: none;
                margin-bottom: 0;
                color: $text-muted;
            }

            h6{
                color: rgba($dark,.5);
                margin-bottom: 0;
            }

            &.active {
                background: mix($primary, $white, 10%);
               
                margin-bottom: 1rem;
                padding: 1.5rem 1.5rem;
                transition: all .2s ease-in;

                i{
                    color: $dark;
                }

                p{
                    display: block;
                    color: $dark;
                }

                h6{
                    color: $dark;
                    margin-bottom: 0.5rem;
                    font-weight: $font-weight-semibold;
                }

                
            }
        }
    }
}

// Nav pills style
.nav-pills-group{
    display: inline-flex;
    background-color: $gray-100;
    border-radius: 5rem;
    padding: 0.375rem;

    .nav-link{
        padding: 0.5rem 1.5rem;
        color: $gray-500;
        font-size: $font-size-sm;

        &.active{
            background-color: $white;
            box-shadow: $box-shadow-sm;
            border-radius: 5rem;
            font-weight: $font-weight-medium;
        }
    }
}

// Custom nav style
.nav-flat{

    .nav-item{

        width: 100%;

        @include media-breakpoint-up(sm){
            width: auto;

            &:not(:last-of-type){
                border-right: 1px solid $border-color;
            }
        }
      
    }

    .nav-link{
        text-align: center;
        font-weight: $font-weight-semibold;
        color: rgba($dark,.4);
        font-size: $font-size-sm;
    }

    &.nav-flat-primary{
        .nav-link{
            &.active{
                background-color: mix($primary, $white, 3%);
                color: $primary;
            }
        }
    }
    &.nav-flat-success{
        .nav-link{
            &.active{
                background-color: mix($success, $white, 3%);
                color: $success;
            }
        }
    }
}