//
// MISCELLANEOUS
// 

// Bootstarp tooltip
.bs-tooltip-bottom {
    .arrow {
        top: 1px;
    }
}


// Blog Cover
.blog-cover {
    position: relative;
    overflow: hidden;

    &:not(.card-img-top)::before{
        border-radius: $border-radius;
    }

    .badge {
        z-index: 1;
    }

    &::before {
        content: "";
        display: block;
        background: rgba($color: $black, $alpha: 0.125);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    div{
        z-index: 2;
    }

    img {
        width: 100%;
        height: 13.75rem;
        object-fit: cover;
        transition: .3s ease-in-out;
        transform: rotate(0) scale(1); 
    }
}

// Sticky Panel
.sticky-panel-top{
    position: sticky;
    top: 5.5rem;
    z-index: 10;
}

// Media Style
.media{
    .media-count {
        max-width: 2.25rem;
        min-width: 2.25rem;
        max-height: 2.25rem;
        min-height: 2.25rem;
        border-radius: 31.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        
        span {
            font-weight: $font-weight-medium;
        }
    } 
}


// Quote style
.quote{
    @include media-breakpoint-up(lg){
        border-left: 0.25rem solid $primary;
        padding-left: 1.25rem;
    }
}


// Popover style
.popover-header {
    color: $white;
}


// Hero Images group
.hero-img-group-1{
    position: relative;

    .hero-img-left{
        position: absolute;
        bottom: -60px;
        left: -40px;    
        transform: scale(0.8);

        @include media-breakpoint-up(md){
            left: 20px;
        }
        
        @include media-breakpoint-up(lg){
            bottom: -80px;
            left: -60px;
        }
        
    }

    .hero-img-right{
        position: absolute;
        top: 95px;
        right: -100px;
        transform: scale(0.8);

        @include media-breakpoint-up(md){
            top: 60px;
            right: -16px;
        }

        @include media-breakpoint-up(lg){
            top: 60px;
            right: -90px;
        }

        @include media-breakpoint-up(xxl){
            top: -10px;
        }

    }
}


// Authentication
.authfullpage{
  display: grid;
  align-items: center;
  background-image: url("./../images/media/bg-cover-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;

  .brand-logo{
    margin: 1.25rem 0;
  }
}

.password-input{

    .input-group-prepend{

        .input-group-text{
            position: absolute;
            font-size: 18px;
            color: #9da6af;
            top: 5px;
            right: 0;
            border: 0;
            background-color: transparent;
            z-index: 5;
        }
    }
    
}
