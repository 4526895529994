//
// TABLE
// 

.table {

    &.table-lg {

        th,
        td {
            padding: 1rem 0.75rem;
        }
    }
}

// Pricing Table
table.table-details {
    background-color: $white;

    thead {
        box-shadow: -1px 0.3125rem 0.25rem -0.3125rem $gray-400;
        border-bottom: 0;

        th {
            border: 0;

            &:not(:first-child) {
                text-align: center;
            }
        }
    }

    tbody {
        .table-details-header {
            margin-bottom: 0;
            background-color: mix($primary, $white, 3%);

            th,
            td {
                letter-spacing: normal;
                white-space: nowrap;
                font-size: 1.2rem;
                font-weight: $font-weight-medium;
            }

        }
    }

    tr {

        th,
        td {
            width: 25%;

            padding: 1.5rem 0.75rem;

            h6 {
                margin-bottom: 1rem;
            }

            p {
                color: $text-muted;
                margin-bottom: 0;
                font-size: $font-size-sm;
            }
        }

        td {
            text-align: center;
        }
    }
}