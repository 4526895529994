//
// PARALLAX
// 

.parallax-banner {
    position: relative;
    overflow: hidden;
    max-height: 35rem;
    height: auto;

    @include media-breakpoint-up(sm) {
        height: 100%;
    }

    &::before {
        content: "";
        background-color: rgba($dark, 0.5);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .parallax-banner-wrapper {
        position: absolute;
        top: 0;
        width: 100%;

        img {
            width: 100%;
            height: 38.75rem;
            object-fit: cover;
        }
    }

    .parallax-content {
        position: relative;
        z-index: 2;

        h1,
        p {
            color: $white;
        }
    }
}
