//
// CAROUSEL
// 

.carousel {

    &.carousel-bottom-gradient {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(to top, $black 0%, rgba(255, 255, 255, 0)) 40%;
        }
    }
}

.carousel-indicators li {
    border-radius: $border-radius * 2;
}



// Customer Stories Carousel
.customers-stories{
    .swiper-slide-prev,
    .swiper-slide-next{
        opacity: 0;
    }
}


.swiper-button-prev,
.swiper-button-next {
    display: inline-flex;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    border: 1px solid $border-color;
    border-radius: 6.25rem;
    background-color: $white;
    color: $text-muted;

    &::after {
        font-size: 1rem;
        font-weight: $font-weight-bold;
    }

    &:focus {
        outline: none;
        box-shadow: $box-shadow;
    }
}
