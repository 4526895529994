//
// STEPS
// 

.steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    li {
      flex-basis: 25%;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      text-align: center;
      position: relative;
      margin-bottom: 2rem;
      
      &:last-child{
        margin-bottom: 0;

        p{
          margin-bottom: 0;
        }
      }
  
      &:not(:first-child):before {
        content: '';
        position: absolute;
        top: 0.9375rem;
        left: -0.625rem;
        right: 0;
        bottom: 0;
        background-color: $light;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        height: 0.3125rem;
        width: 45%;
      }
  
      &:not(:last-child):after {
        content: '';
        position: absolute;
        top: 0.9375rem;
        left: auto;
        right: -0.625rem;
        bottom: 0;
        background-color: mix($primary, $white, 10%);
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        height: 0.3125rem;
        width: 45%;
      }
  
      span {
        background-color: $primary;
        color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;
        display: block;
        font-size: 1rem;
        line-height: 2.1875rem;
        margin: 0 auto 1.5625rem;
        position: relative;
        height: 2.1875rem;
        width: 2.1875rem;
  
        &:before {
          content: '';
          position: absolute;
          top: -0.375rem;
          left: -0.375rem;
          right: 0;
          bottom: 0;
          background-color: $gray-100;
          border: 1px solid $border-color;
          border-radius: 50%;
          height: 2.8125rem;
          width: 2.8125rem;
          z-index: -1;
        }
      }
  
      p {
        color: $text-muted;
        font-weight: $font-weight-base;
        margin-top: 0.3125rem;
        font-size: $font-size-sm;
      }
    }
  
    &.vertical {
      display: block;
  
      li {
        text-align: left;
        padding-left: 4.1875rem;
  
        &:before {
          content: none;
        }
  
        &:after {
          border: none;
          border-left: 1px solid $border-color;
          border-right: 1px solid $border-color;
          top: 2.5625rem;
          left: 1.25rem;
          height: 100%;
          width: 0.3125rem;
        }
  
        span {
          margin: 0;
          position: absolute;
          left: 0.3125rem;
          top: 0.3125rem;
          text-align: center;
        }
      }
    }
  }
  
  
