//
// ALERT
//

.alert {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
}

.alert-modern {
    display: inline-flex;
    align-items: center;
    padding: .375rem 1rem .375rem .5rem;
    border-radius: 50rem;
    border: 0;
}