//
// DIVIDER
// 
.divider {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 1.5rem auto;
    color: $gray-500;
    width: 100%;

    &::after,
    &::before {
        content: '';
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        height: 1px;
        background-color: $gray-200
    }

    &::before {
        display: block;
        margin-right: .5rem
    }

    &::after {
        margin-left: .5rem
    }
}