//
// INTEGRATION
// 

// Integration Logo style
.integration-app-logo{
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

// Integration Wrapper
.integration-wrapper{
    position: relative;
    min-height: 26.875rem;

    @include media-breakpoint-up(sm){
        min-height: 31.25rem;
    }

    .integration-item{
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        background: $white;
        border-radius: 50%;
        border: 1px solid rgba($dark,.1);
        transition: all 0.2s;

        &:hover{
            box-shadow: $box-shadow-smooth-3;
        }

        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
            width: 100%;
            height: 0;
        }


        &:nth-child(1) {
            height: 6.25rem;
            width: 6.25rem;
            left: 15%;
            top: 0;

            @include media-breakpoint-up(sm){
                width: 9.375rem;
                height: 9.375rem;
            }
        }

        &:nth-child(2) {
            width: 5.625rem;
            left: 55%;
            top: 2.5rem;

            @include media-breakpoint-up(sm){
                width: 7.5rem;
            }
        }

        &:nth-child(3) {
            width: 6.25rem;
            left: 0;
            top: 7.5rem;
            

            @include media-breakpoint-up(sm){
                width: 7.5rem;
                top: 10rem;
            }
        }

        &:nth-child(4) {
            width: 6.25rem;
            left: 40%;
            top: 9.375rem;

            @include media-breakpoint-up(sm){
                width: 9.375rem;
                left: 35%;
                top: 10rem;

            }
        }

        &:nth-child(5) {
            width: 6.25rem;
            left: 60%;
            top: 15.625rem;

            @include media-breakpoint-up(sm){
                width: 9.375rem;
                left: 70%;
                top: 12.5rem;
            }
        }

        &:nth-child(6) {
            width: 4.375rem;
            left: 10%;
            top: 15rem;

            @include media-breakpoint-up(sm){
                width: 7.5rem;
                left: 10%;
                top: 18.75rem;
            }
        }

        &:nth-child(7) {
            width: 6.25rem;
            left: 27%;
            top: 19.375rem;

            @include media-breakpoint-up(sm){
                width: 9.375rem;
                left: 42%;
                top: 21.875rem;
            }
        }

        .integration-image{

            display: inline-block;
            width: 100%;
            transform: translate(50%);
            
            img{
                margin-left: auto;
                margin-right: auto;
                display: block;
                max-width: 100%;
                max-height: 100%;
                height: auto;
            }
        }
    }
}